<template>
  <el-tabs type="border-card" @tab-click="tabchange">
    <el-tab-pane label="等级奖学金审批流程">
      <sy-form v-if="showform" :list="list" ref="form"></sy-form>
      <div class="btngroup">
        <el-button type="primary" @click="Save">提交保存</el-button>
      </div>
    </el-tab-pane>
  </el-tabs>
</template>
<script>
import stepform from '../../components/common/setpset.vue'
import { GetDJStep, DJLCPZSave } from '../../api/jxj'
export default {
  components: {
    'sy-form': stepform, // 挂载组件
  },
  data() {
    return {
      list: [],
      showform: false,
    }
  },
  created() {
    GetDJStep().then((res) => {
      if (res.code === 200) {
        this.list = res.data
        this.showform = true
      }
    })
  },
  methods: {
    tabchange(e) {},
    Save() {
      var data = []
      this.steplist = this.$refs.form.steplist
      for (var i = 0; i < this.steplist.length; i++) {
        data.push({
          ID: this.steplist[i].id,
          BMBH: this.steplist[i].sqbmStr
            ? this.steplist[i].sqbmStr.toString()
            : '',
          JGH: this.steplist[i].sqryStr
            ? this.steplist[i].sqryStr.toString()
            : '',
          RoleID: this.steplist[i].sqjsStr
            ? this.steplist[i].sqjsStr.toString()
            : '',
          STEP: this.steplist[i].step,
          LX: this.steplist[i].lx,
          BZMC: this.steplist[i].bzmc,
        })
      }
      DJLCPZSave({ pcsz: data }).then((res) => {
        if (res.code === 200) {
          GetDJStep().then((res) => {
            if (res.code === 200) {
              this.steplist = res.data
            }
            this.$message.success(res.message)
          })
        } else {
          this.$message.error(res.message)
        }
      })
    },
  },
}
</script>

<style>
.stepcontainer {
  width: 800px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item {
  box-sizing: border-box;
  background-color: #fff;
  padding: 0 60px;
}
.title {
  display: flex;
  justify-content: space-around;
  background: rgb(87, 97, 255);
  color: rgb(255, 255, 255);
  width: 211px;
  height: 40px;
  line-height: 40px;
  border-radius: 0px 0px 8px 8px;
  margin: 0 auto;
}
.ry-top-left {
  font-size: 14px;
  color: #606266;
}
.tag-warp {
  background: rgb(249, 249, 249);
  border-radius: 5px;
  padding: 10px;
}
.el-tag {
  margin-right: 10px;
  margin-top: 5px;
}
.diatitle {
  font-weight: 700;
  font-size: 15px;
  padding-bottom: 20px;
}
.yxzbox {
  width: 100%;
}
.yxzitem {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dia-i1 {
  margin-right: 5px;
  color: #e6a23c;
}
.dia-i2 {
  margin-right: 8px;
  color: #409eff;
}
.del-i {
  color: red;
  font-size: 18px;
  cursor: pointer;
}
.btngroup {
  text-align: center;
}
</style>
